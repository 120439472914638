<template>
  <div class="certForm">
    <el-dialog
      title=""
      top="30vh"
      :show-close="false"
      custom-class="certFormDialog"
      :visible.sync="dialogVisible"
      width="302px"
      >
        <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E8%AF%81%E4%B9%A6%E6%9F%A5%E8%AF%A2_03.png"/>
        <p>请至少输入两项查询条件</p>
        <div class="dialog-footer" @click="dialogVisible = false">关闭</div>
    </el-dialog>
  
    <div class="title"><p>{{ ifForm ? '证书查询' : '查询结果'}}</p><img @click="ifForm = true"  v-show="!ifForm" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/certFormBtn.png"/></div>
    <el-form :model="ruleForm" ref="ruleForm" label-width="80px" class="form" v-show="ifForm">
      <el-form-item label="证书编号" prop="cert_no" class="input">
        <input v-model.number="ruleForm.cert_no" placeholder="请输入证书编码"/>
      </el-form-item>
      <el-form-item label="身份证号" class="input">
          <input v-model="ruleForm.cid" placeholder="请输入身份证号码"/>
      </el-form-item>
      <el-form-item label="姓名" class="input">
          <input v-model="ruleForm.name" placeholder="请输入姓名"/>
          <p>注：以上三项输入任意两项即可查询。</p>
      </el-form-item>
  
      <el-form-item  label="" class="btn">
        <el-button type="primary" :loading="pageLoading" @click="submitForm('ruleForm')">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="data" v-show="!ifForm">
      <div class="list" v-if="list.length">
        <div class="listItem" v-for="(item,index) in list">
          <cert-save :lessonNm="item.cert_name" :certInfoItem="item" :userInfoItem="user_list" :index="index"  type="true"></cert-save>
        </div>
      </div>
      <div class="noData" v-else>
         <div class="noDataTop">
          <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/%E8%AF%81%E4%B9%A6%E6%9F%A5%E8%AF%A2noData.png"/>
          对不起，没有查询到相关信息
        </div>
        <div class="noDataContent">
          <b>该怎么办？</b>
          <p>1.请确认您输入了正确的证书编号、证件号码或姓名</p>
          <p>2.若无法查询到证书，请联系客服：13811674685</p>
        </div>
      </div>
    </div>
      
  </div>
  </template>
  
  <script>
    import CertSave from './CertSave.vue';
  export default {
    name: "cert",
    components: {CertSave},
    data() {
      var checkPhone = (rule, value, callback) => {
          if (!value) {
            return callback(new Error('请输入手机号'));
          }
            const regPhone = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|17[0-9]{1}|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
           
            if (!regPhone.test(value)) {
              callback(new Error('请填写正确的手机号'));
            }else {
                callback();
            }
            
        };
        var checkID = (rule, value, callback) => {
          if (!value) {
            return callback(new Error('请输入身份证号码'));
          }
            const arg1 = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      
            if (!arg1.test(value)) {
              callback(new Error('请输入正确的身份证号码'));
            }else {
                callback();
            }
            
        };
      return {
        dialogVisible: false,
        pageLoading:false,
        user_list:null,
          //  uicd:'',
        ruleForm: {
          name:'',
          cert_no:'',
          // cid:'',
        },
        ifForm: true,
        list:[]
      }
    },
    mounted() {
      let userData = JSON.parse(localStorage.getItem('user'))
      this.ruleForm.name = userData.name || userData.nickname
      // this.ruleForm.mobile = userData.mobile
    },
    destroyed() {},
    computed: {
      plat() {
        return this.$store.state.plat_id
      }
    },
    methods: {
      submitForm() {
        let flag = 0
        for(let v of Object.values(this.ruleForm)){
          console.log(v)
          if(v) flag++
        }
        console.log(flag)
        if(flag<2){
          this.dialogVisible = true
          return
        }
        if(this.ruleForm.cid){
          const arg1 = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      
          if (!arg1.test(this.ruleForm.cid)) {
            this.$toast('请输入正确的身份证号码')
            return
          }
        }
        this.pageLoading = true
        this.$request.certSearch2(this.ruleForm).then(res=>{
          this.pageLoading = false
          if(res.code == 1){
            this.ifForm = false
            return
          }
          if(res.code==0) {
            this.ifForm = false
            this.list = res.data && res.data.cret_list || []
            this.user_list = res.data.user_list
            }else{
              this.list = []
              this.$toast(res.msg)
            }
        })
            
         
      },
    },
    beforeRouteUpdate(to, from, next) {
      console.log('beforeRouteUpdate')
      console.log(to)
      next()
    },
    beforeDestroy(){
    }
  
  };
  </script>
  <style lang="scss">
  .certFormDialog{
    border-radius: 20px;
    .el-dialog__header{
      padding: 0 !important;
    }
    .el-dialog__body{
      display: flex;
      flex-direction: column;
      align-items: center;
      p{
        font-size: 18px;
        color: #333333;
        width: 260px;
        text-align: center;
        border-bottom: 1px dashed #f1f1f1;
        padding-top: 20px;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      .dialog-footer{
        width: 166px;
        height: 36px;
        line-height: 36px;
        border-radius: 18px;
        font-size: 16px;
        color: #fff;
        background-color: #0080d6;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
  </style>
  <style lang="scss" scoped>
  .certForm {
    min-height: calc(100vh - 230px);
    >.title {
      font-size: 24px;
      color: #242424;
      font-weight: bold;
      border-bottom: 1px dashed #f0f0f0;
      padding-top: 30px;
      padding-bottom: 20px;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      p{
        flex: 1;
      }
      img{
        cursor: pointer;
      }
    }
    .form{
      width: 100%;
      padding: 40px 305px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      font-family: SourceHanSans;
    }
    
    .input :deep .el-form-item__label{
      color: #333;
      font-size: 16px;
      text-align: justify !important;
      text-align-last: justify !important;
      font-family: SourceHanSansBold;
      position: relative;
      &::after{
        position: absolute;
        right: 5px;
        top: 0;
        content: ':';
      }
    }
    .input :deep .el-form-item__content{
      padding-left: 15px;
      
      p{
        margin-left: 5px;
        font-size: 14px;
        color: #666;
        cursor: pointer;
      }
      input{
        background: #FFFFFF;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        border: 1px solid #dadada;
        font-size: 14px;
        color: #666666;
        width: 100%;
        text-indent: 26px;
      }
      .el-button{
        margin-left: 10px;
        width: 112px;
        flex-shrink: 0;
        
      }
    }
  
    .input .gray{
      color: #333;
    }
  
  
    :deep .el-button--primary{
      border: 0;
      width: 413px;
      height: 40px;
      line-height: 40px;
      background: #0080d6;
      border-radius: 6px;
      color: #fff;
      font-size: 16px;
      margin-top: 30px;
      padding: 0;
    }
  }
  .data{
    .list{
      .listItem{
        margin: 0 auto 20px;
        width: 612px;
        box-shadow: 0 0px 18px rgba(49,54,70,0.1);
      }
    }
    .noData{
      width: 1000px;
      margin: 0 auto;
      border-radius: 6px;
      background-color: #f9f9f9;
      overflow: hidden;
      >div{
        padding-left: 350px;
      }
      .noDataTop{
        background-color: #f0f0f1;
        font-family: SourceHanSansBold;
        color: #333;
        font-size: 20px;
        display: flex;
        align-items: center;
        height: 80px;
        img{
          margin-right: 15px;
        }
      }
      .noDataContent{
        font-family: SourceHanSans;
        padding-top: 36px;
        padding-bottom: 36px;
        b{
          display: block;
          font-size: 16px;
          color: #242424;
          margin-bottom: 15px;
        }
        p{
          color: #666666;
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }
  </style>
  
  <style lang="scss">
  
  </style>
  